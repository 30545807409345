<template>
    <div class="ds-container">
      <section class="ds-header">
        <div class="ds-title">
          <h2>Lotes a enviar</h2>
          <span id="help-icon" class="icon-box">
            <HelpCircle class="icon stroke" />
          </span>
          <b-tooltip target="help-icon" placement="bottom">
            Visualizar lotes a enviar.
          </b-tooltip>
        </div>
        <div class="btn-title-container">
          <b-button variant="primary" v-if="selected.length" @click="generateXML">
            <Download class="download-icon" />
            Fazer download
          </b-button>
          <b-button
            v-can="'FatTissLotAEnv2'"
            variant="outline-primary"
            v-if="selected.length"
            @click="sentBatches"
          >
            Definir lotes como enviados
          </b-button>
          <b-button
            variant="outline-primary"
            @click="$bvModal.show('print-batch-modal')"
          >
            Folha de rosto
          </b-button>
          <!-- <b-button variant="outline-primary no-print" @click="print">Imprimir</b-button> -->
        </div>
      </section>
      <section>
        <LotsFilters @change="onChangeFilter" class="no-print" />
      </section>
      <section>
        <LotsToSendTable
          :tableRows="tableRows"
          @delete-batch="removeItem"
          @selected-batches="selectedBatches"
          :getTypes="getTypes"
          @onOrdenate="handleOrdenate"
          :openHistoric="openHistoric"
          :getGuide="getGuide"
        />
        <NoFiltersResults
          v-if="!tableRows.length"
          message="Os filtros aplicados não geraram nenhum resultado."
        />
        <div class="pagination-position">
          <b-pagination
            size="sm"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
          />
        </div>
      </section>

      <TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />

      <TissGuidesModalsContainer
        :patient="patient"
        :tissGuide="tissGuide"
        :clinicHealthPlan="clinicHealthPlan"
        :appointmentId="tissGuide.appointment_id"
        :getLastTissGuide="getLastTissGuide"
        :clearTissGuide="clearTissGuide"
        :required_fields="tissRequiredCustomField"
        :readonly="false"
        :setTissGuide="setTissGuide"
        :getGuide="getGuide"
      />
    </div>
  </template>
  
  <script>
  import { saveAs } from 'file-saver'
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  import { getCurrentClinic } from '@/utils/localStorageManager'
  import { getTypes, handleGuideUpdate } from '@/utils/invoicingTissHelper'
  import { TissBatch } from '@/modules/tiss/manageGuides/utils/statuses'

  export default {
    components: {
      Download: () => import('@/assets/icons/download.svg'),
      HelpCircle: () => import('@/assets/icons/help-circle.svg'),
      LotsFilters: () => import('@/components/Tiss/Generic/Filters/LotsToSendFilter'),
      LotsToSendTable: () => import('@/components/Tiss/Table/LotsToSendTable'),
      NoFiltersResults: () => import('@/components/General/noFilterResults'),
      TissGuideAuditModal: () => import('@/components/Tiss/Modal/TissGuideAuditModal.vue'),
      TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue')
    },
    props: {
      print: Function
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        tableRows: [],
        selected: [],
        filters: {
          orderBy: {
            column: 'created_at',
            order: 'desc'
          }
        },
        tissGuideAudits: [],

        patient: {},
        tissGuide: {},
        clinicHealthPlan: {},
        tissRequiredCustomField: {}
      }
    },
    computed: {
      selectedIds() {
        return this.selected.map(selected => selected.id)
      }
    },
    async created() {
      await this.getBatches()
    },
    methods: {
      getTypes,
      parseNumberToMoney,
      async getBatches() {
        const isLoading = this.$loading.show()
        const filters = this.filters
        filters.type = filters.type?.value

        try {
          this.tableRows = []
          const { data } = await this.api.getTissBatches(
            this.clinic.id,
            this.currentPage,
            TissBatch.BATCH_STATUS_TO_SENT,
            filters
          )
          this.currentPage = data.current_page
          this.perPage = data.per_page
          this.totalItems = data.total
  
          this.fillRowData(data.data)
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async sentBatches() {
        const table = this.getHtmlTable();
        this.$swal.fire({
          title: 'Enviar lote',
          html: table,
          showCancelButton: true,
          confirmButtonColor: '#305BF2',
          confirmButtonText: 'Confirmar',
          cancelButtonColor: 'transparent',
          cancelButtonText: '<span style="color: red;">Cancelar</span>',
          cancelButtonHoverColor: '#ff0000',
          showCloseButton: true,
          reverseButtons: true,
          customClass: {
            popup: "custom-swal-batch-review",
          },
        }).then(async (result) => {
          if(result.isConfirmed){
            const isLoading = this.$loading.show()
            try {
              const selectedRequest = this.selected.map(selected => {
                return {
                  id: selected.id,
                  tiss_guide_id: selected.tiss_guide_id,
                  status: TissBatch.BATCH_STATUS_SENT,
                  clinic_id: this.clinic.id
                }
              })
              const { data } = await this.api.updateManyTissBatches({
                data: selectedRequest
              })
              this.tableRows = this.tableRows.filter(row => {
                return !data.includes(row.id)
              })
              this.selected = []
              this.$router.push('lotes-enviados')
              this.$toast.success('Lotes movidos para aba Lotes enviados')
            } catch (error) {
              this.$toast.error(error.message)
            } finally {
              isLoading.hide()
            }
          }
        })
      },
      parseDate(date) {
        return this.moment(date).format('DD/MM/YYYY')
      },
      fillRowData(batches) {
        batches.forEach(data => {
          const row = {
            id: data.id,
            tiss_guide_id: data.tiss_guide_id,
            Checked: false,
            Arquivo: 'XML',
            Criação: this.parseDate(data.created_at),
            Convênio:
              data.tiss_invoice.clinic_health_plan.health_plan.fantasy_name,
            'Nº Lote': data.number,
            'Nº Fatura': data.tiss_invoice.number,
            'Tipo de guia': this.getTypes(data.type),
            'Nº guias': data.tiss_guides_count,
            'Versão TISS': data.xml_version,
            'Valor total': this.parseNumberToMoney(data.items_total),
            Conferido: {
              Porcentagem:
              (data.tiss_guides_count > 0 ? ((data.total_guides_conferred / data.tiss_guides_count).toFixed(2) * 100) : 0) + 
                '%',
              Conferidas: data.total_guides_conferred,
              'Não conferidas':
                data.tiss_guides_count - data.total_guides_conferred
            },
            Options: ''
          }
          this.tableRows.push(row)
        })
      },
      removeItem(id) {
        this.tableRows.splice(
          this.tableRows.findIndex(item => item.id === id),
          1
        )
      },
      selectedBatches(value) {
        this.selected = value
      },
      async onChangeFilter(filter) {
        this.filters = filter
        await this.getBatches()
      },
      async generateXML() {
        const isLoading = this.$loading.show()
        try {
          const { data } = await this.api.tissBatchXML({
            ids: this.selectedIds,
            clinicId: this.clinic.id
          })
          data.map(async data => {
            const blob = new Blob([data.xml], { type: 'text/xml;charset=utf-8' })
            saveAs(blob, data.name)
          })
        } catch (error) {
          if(error.message.includes('code')) this.$toast.warning('XML não gerado! Existem guias sem procedimentos preenchidos dentro do lote.') 
          else this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async handleOrdenate(orderBy) {
        this.$set(this.filters, 'orderBy', orderBy)
        await this.getBatches()
      },
      async openHistoric(tissGuideId) {
        await this.getTissGuideAudits(tissGuideId)
        this.$bvModal.show('tiss-guide-audit-modal')
      },
      async getTissGuideAudits(tissGuideId) {
        const isLoading = this.$loading.show()
        try {
          const response = await this.api.getTissGuideAudits(tissGuideId)
          this.tissGuideAudits = response.data
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async getGuide(tissGuide, notOpen) {
        const isLoading = this.$loading.show()
        try {
          const id = tissGuide?.id ?? tissGuide
          const response = await this.api.getTissGuideById(id)
          this.tissGuide = response.data
          if (notOpen) return
  
          this.patient = this.tissGuide.patient
          await this.getClinicHealthPlan(
            this.tissGuide.invoicing_tiss_setting_id
          )
          this.handleGuide(this.tissGuide.guide_type)
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      handleGuide(type) {
        const modal = handleGuideUpdate(type)
        this.$bvModal.show(modal)
      },
      async getClinicHealthPlan(invoicingTissSettingId) {
        if (!this.clinic.id || !invoicingTissSettingId) return
        try {
          const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
            this.clinic.id,
            invoicingTissSettingId
          )
          this.clinicHealthPlan = response.data
          this.tissRequiredCustomField =
            this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
      async getLastTissGuide(data) {
        await this.getGuide(data?.guide_id, true)
      },
      setTissGuide(tissGuide){
        this.tissGuide = null
        this.tissGuide = tissGuide
      },
      clearTissGuide() {
        this.tissGuide = {}
      },
      getHtmlTable(){
        let tablesHtml = `
        <div style="text-align: left; padding: 10px;">
          Essa ação é definitiva, e você <strong>não poderá mais editar as guias deste(s) lote(s)</strong>. Deseja realmente definir esse(s) lote(s) como enviado(s)?
        </div>
        <div class="custom-batch-review-tables-container">`
        this.selected.forEach(item => {
          tablesHtml += `<table class="custom-batch-review-table">
            <thead>
              <tr class="healthplan-tr">
                <th colspan="7">
                  <div class="healthplan-info">
                    <div class="d-flex" style="justify-content: space-between">
                      <span id="healthplan-label">
                        Convênio: 
                      </span>
                    </div>
                    <span>${item.Convênio}</span>
                  </div>
                </th>
              </tr>
              <tr class="batch-tr">
                <th>Nº Lote</th>
                <th>N॰ Fatura</th>
                <th>Tipo de guia</th>
                <th>Nº Guias</th>
                <th>Versão TISS</th>
                <th>Valor total</th>
                <th>Conferido</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${item['Nº Lote']}</td>
                <td>${item['Nº Fatura']}</td>
                <td>${item['Tipo de guia']}</td>
                <td>${item['Nº guias']}</td>
                <td>${item['Versão TISS']}</td>
                <td>${item['Valor total']}</td>
                <td>
                  <span :id="'popover-' + index" style="border-radius: 30px; padding: 4px 10px; font-weight: 700; ${this.getColorStyles(item.Conferido.Porcentagem)}">
                    ${item.Conferido.Porcentagem}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>`
        });

        tablesHtml += '</div>';

        return tablesHtml;
      },
      getColorStyles(percentage) {
      const value = parseInt(percentage.replace(/[^0-9]/g, ''));
        if (value < 10) {
          return 'background-color: var(--light-orange-200); color: var(--states-error);';
        } else if (value < 20) {
          return 'background-color: #fff3e1; color: var(--orange);';
        } else if (value < 50) {
          return 'background-color: #fff6d4; color: #bc8212;';
        } else if (value < 80) {
          return 'background-color: var(--blue-100); color: var(--blue-500);';
        } else {
          return 'background-color: var(--states-success-soft-green); color: var(--states-success-dark-green);';
        }
      },
    },
    watch: {
      async currentPage() {
        await this.getBatches()
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .ds-container {
    padding: 30px 10px;
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 0 0;
    }
    .ds-title {
      h2 {
        display: inline-flex;
        align-items: center;
        font-family: 'Red Hat Display';
        font-weight: 500;
        font-size: 18px;
      }
  
      .icon {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        stroke: var(--neutral-500);
      }
    }
  
    .btn-title-container {
      button {
        margin-left: 15px;
      }
  
      .download-icon {
        height: 16px;
        width: 16px;
        margin-right: 7px;
        margin-bottom: 3px;
      }

    }
  }
  </style>
  